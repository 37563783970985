import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { auth } from '../firebase'; // Pastikan mengimpor auth dari firebase.js

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/PortfolioView.vue'),
    meta: { title: 'Portfolio' }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: { title: 'Contact' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginForm.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminDasboard.vue'),
    meta: { requiresAuth: true } // Pastikan hanya ada satu meta
  },
  {
    path: '/admin/project',
    name: 'adminProject', 
    component: () => import('../views/CrudProject.vue'), // Ini akan menampilkan CrudProject tanpa layout Admin
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/blog',
    name: 'adminBlog', 
    component: () => import('../views/CrudBlog.vue'), // Ini akan menampilkan CrudProject tanpa layout Admin
    meta: { requiresAuth: true }
  },
  {
    path: '/read/:slug/:id',
    name: 'articleDetail',
    component: () => import('../views/ArticleView.vue'),
    meta: { title: 'Blog' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Middleware untuk cek autentikasi sebelum akses ke dashboard
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !auth.currentUser) {
    next('/login');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - Luthfi {}'; // Mengambil judul dari meta atau mengatur judul default jika tidak ada
  next();
});

export default router
