// Import modul Firebase v9+ dengan API modular
import { initializeApp } from "firebase/app"; // Modul baru untuk Firebase v9+
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; // Modul Auth
import { getStorage } from "firebase/storage"; // Modul Storage
import { getFirestore } from "firebase/firestore";

// Konfigurasi Firebase Anda
const firebaseConfig = {
  apiKey: "AIzaSyBBxc4Jdb8kwPB_BI6SmuVRCpaSqwx2_Ck",
  authDomain: "class-712bc.firebaseapp.com",
  projectId: "class-712bc",
  storageBucket: "class-712bc.appspot.com",
  messagingSenderId: "392367301389",
  appId: "1:392367301389:web:4277f1943044798d97c644",
};

// Inisialisasi Firebase App
const app = initializeApp(firebaseConfig);

// Mendapatkan referensi Firebase Authentication dan Storage
const auth = getAuth(app); // Mendapatkan instansi auth
const storage = getStorage(app); // Mendapatkan instansi Firebase Storage

const db = getFirestore(app);

// Fungsi login dengan email dan password
export const loginWithEmailPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password); // Fungsi login menggunakan email dan password
};

// Mengekspor auth dan storage untuk digunakan di tempat lain
export { db, auth, storage };
export default app;
